import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Freelance Fullstack Software Engineer`}</p>
    </blockquote>
    <p>{`I'm 23 years old, based in Grand Est, France. I graduated at Université de Strasbourg in 2022 and proceeded to work mostly in Banking and Real Estate domains.`}</p>
    <p>{`You can discover my past and present public projects on this portfolio, but remember that many NDA projects can not be displayed here. You will also find a recap of my skills and hobbies.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      