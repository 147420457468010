import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Randonnée à Dutt" link="https://pierre-even.itch.io/randonnee-a-dutt" bg="linear-gradient(to right, #b5179e 0%, #7209b7 100%)" mdxType="ProjectCard">
	Serious Game made in 4 days with 3 other students based on research works about hikings in the XIXth century in Germany
      <pre><code parentName="pre" {...{}}>{`> Unreal Engine, C++, basic character AI, Scrum
`}</code></pre>
    </ProjectCard>
    <ProjectCard title="Postier de Fakarava" link="https://pierre-even.itch.io/le-postier-de-fakarava" bg="linear-gradient(to right, #560bad 0%, #480ca8 100%)" mdxType="ProjectCard">
	Post office game taking place in Fakarava (French remote island) made in 48h in a GameJam with another student
      <pre><code parentName="pre" {...{}}>{`> Unreal Engine, C++, Simple gravity physics and aerodynamics
`}</code></pre>
    </ProjectCard>
    <ProjectCard title="Python Cozmo" link="https://github.com/mathieu900v/Python-Cozmo" bg="linear-gradient(to right, #3a0ca3 0%, #3f37c9 100%)" mdxType="ProjectCard">
	Complete solution in a website form for helping pupils to program a Cozmo Robot
      <pre><code parentName="pre" {...{}}>{`> Raspberry Pi, Ubuntu-server, Django, SQL, HTML5, CSS
`}</code></pre>
    </ProjectCard>
    <ProjectCard title="Black Spirit" link="#" bg="linear-gradient(to right, #b5179e 0%, #3a0ca3 100%)" mdxType="ProjectCard">
	E-Commerce website for a clothing brand
      <pre><code parentName="pre" {...{}}>{`> React, PostgreSQL, Firebase, HTML5, CSS
`}</code></pre>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      